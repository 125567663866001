<template>
  <div class="test_section">
    <div class="timer" :class="{time_remaining_change_effect: timeRemainingChangeEffect}">
      <count-down-timer
        :duration="timeAllowed"
        :notifyTimeRemaining="timeRemaining"
        @timeRemaining="onTimeRemainingHandler($event)"
        @userDone="handleUserDone($event)"
        :userDone="userDone"
        v-if="!loading" />
      <p v-if="timeElapsed">
        Timeout
      </p>
    </div>

    <div class="" v-if="!loading">
      <button type="button" @click="handleTestSubmit()">
        Finish Test
      </button>
    </div>

    <div v-if="loading">
      <b-spinner small></b-spinner>
      Loading
    </div>

    <div v-if="submitting">
      <b-spinner small></b-spinner>
      Submitting
    </div>

    <div v-if="!loading && !submitting">
      <div v-for="testQuestion in paginatedTestQuestions" :key="testQuestion.id">
        <div class="quiz_card">
          <div v-html="testQuestion.question"></div>

          <div class="options">
            <div v-for="(testOption, optionIndex) in testQuestion.options" :key="optionIndex">
              <div class="option">
                <label class="">
                  <input
                    v-if="answers[pageNumber]"
                    type="checkbox"
                    v-model="answers[pageNumber].user_answers"
                    :value="optionIndex"
                    :disabled="disableOption(testQuestion.allow_multiple_answers, answers[pageNumber].user_answers, optionIndex)"
                    v-on:click="selectOption($event, optionIndex, pageNumber)"
                    >
                  <span class="label-body">{{testOption}}</span>
                </label>
              </div>
            </div>
          </div>

        </div>

        <span @click="nextPage()" class="next_quiz" v-if="pageNumber < numberOfPages-1" >&gt;</span>
        <span @click="prevPage()" class="prev_quiz" v-if="pageNumber > 0" >&lt;</span>
      </div>

       <div class="page_numbers">
        <span
            @click="jumpToQuiz(questionIndex)"
            v-for="(page, questionIndex) in numberOfPages"
            :key="questionIndex"
            :class="{'page_number':true, 'active': questionIndex === pageNumber, 'answered': answers[questionIndex].user_answers.length >= minAnswerLength }"
            >
          {{ questionIndex + 1 }}
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CountDownTimer from '../components/CountDownTimer.vue';

const { mapActions, mapGetters } = createNamespacedHelpers('staging/test');
export default {
  name: 'TakeTest',
  components: {
    CountDownTimer,
  },
  data() {
    return {
      bookId: this.$route.params.book_id,
      testId: this.$route.params.test_id,
      quizPerPage: 1,
      pageNumber: 0,

      answers: [],
      minAnswerLength: 1,
      completed: false,

      loading: false,
      submitting: false,
      submitted: false,

      timeRemaining: [0, 60],
      timeRemainingChangeEffect: false,
      timeElapsed: false,
      userDone: false,
    };
  },
  methods: {
    ...mapActions(['getTestCreatedByUser', 'gradeTest']),
    nextPage() {
      if (this.pageNumber !== this.numberOfPages - 1) {
        this.pageNumber = this.pageNumber + 1;
      }
    },
    prevPage() {
      if (this.pageNumber !== 0) {
        this.pageNumber = this.pageNumber - 1;
      }
    },
    jumpToQuiz(questionIndex) {
      this.pageNumber = questionIndex;
    },
    selectOption(e, optionIndex, questionIndex) {
      if (e.target.checked) {
        this.answers[questionIndex].user_answers.push(optionIndex);
      } else {
        const indexInUserAnswersArr = this.answers[questionIndex].user_answers.indexOf(optionIndex);
        this.answers[questionIndex].user_answers.splice(indexInUserAnswersArr, 1);
      }
    },
    disableOption(allowMultipleAnswers, optionsArray, optionIndex) {
      const { minAnswerLength } = this;
      return ((!allowMultipleAnswers && optionsArray.length >= minAnswerLength && optionsArray.indexOf(optionIndex) === -1) || this.submitted);
    },
    handleTestSubmit() {
      this.userDone = true;
    },
    handleUserDone(timeSpent) {
      this.sendTestDetailsToServer(timeSpent);
    },
    onTimeRemainingHandler([remainingTime, timeSpent]) {
      if (remainingTime === 60) {
        this.timeRemainingChangeEffect = true;
      } else if (remainingTime === 0) {
        this.timeElapsed = true;
        this.sendTestDetailsToServer(timeSpent);
      } else {
        this.timeElapsed = false;
      }
    },
    sendTestDetailsToServer(timeSpent) {
      this.submitting = true;
      const completedBooleanArray = [];
      this.answers.forEach((element) => {
        if (element.user_answers.length >= 1) {
          completedBooleanArray.push(true);
        } else {
          completedBooleanArray.push(false);
        }
      });

      this.completed = !completedBooleanArray.includes(false);
      const testDetails = {
        id: this.testId,
        time_spent: timeSpent,
        completed: this.completed,
        answers: this.answers,
      };

      this.gradeTest(JSON.stringify(testDetails))
        .then(() => {
          this.submitting = false;
          this.$router.push({ name: 'TestResult', params: { book_id: this.bookId, test_id: this.testId } });
        })
        .catch((err) => {
          this.submitting = false;
          this.submitted = true;
          const errInfo = err.data.message;
          this.$toast.error(errInfo, 'Error');
        });
    },
  },
  computed: {
    ...mapGetters(['testQuizzes', 'timeAllowed', 'testScore']),
    numberOfPages() {
      const quizzesLenght = this.testQuizzes.length;
      const { quizPerPage } = this;
      return Math.ceil(quizzesLenght / quizPerPage);
    },
    paginatedTestQuestions() {
      const start = this.pageNumber;
      const end = start + 1;
      return this.testQuizzes.slice(start, end);
    },
  },
  mounted() {
    const { testId, answers } = this;
    if (!this.testQuizzes || (this.testQuizzes.length === 0)) {
      this.loading = true;
      this.getTestCreatedByUser(testId)
        .then(() => {
          this.testQuizzes.forEach((element) => {
            answers.push({
              id: element.id,
              user_answers: [],
            });
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          const errInfo = err.data.message;
          this.$toast.error(errInfo, 'Error');
        });
    }
  },
};
</script>

<style lang="stylus">
  :root
    --timeout_color: red
    --unaswered_question grey
    --current_question green
    --answered_question black

  .timeout
    color: var(--timeout_color)
  .time_remaining_change_effect
    color: var(--timeout_color)

  .test_section
    position relative

    .next_quiz
      position: absolute;
      bottom: 50%;
      right: 0rem;
      cursor: pointer;
    .prev_quiz
      position: absolute;
      bottom: 50%;
      left: 0rem;
      cursor: pointer;

    .page_numbers
      text-align: center;

      .page_number
        border .1rem solid var(--unaswered_question);
        color var(--unaswered_question)
        margin .1rem;
        padding .5rem 1rem;
        cursor pointer;

      .active
        border .1rem solid var(--current_question)
        color var(--current_question)
      .answered
        border .1rem solid var(--answered_question)
        color var(--answered_question)
</style>
